import Repository from "./Repository";

const PostRegister = "PostRegister.php";
const GetEntries =  "GetEntries.php";
const UpdateRegister = "UpdateRegister.php";
const GetEntry =  "GetEntry.php";
const ResendEmail = "ResendEmail.php"
const login =  "login.php";

export default {	
	postRegister(payload){
		return Repository.post(`${PostRegister}`, payload);		
	},
	updateRegister(payload){
		return Repository.post(`${UpdateRegister}`, payload);		
	},
	resendEmail(formData){
		return Repository.post(`${ResendEmail}`, formData);		
	},
	getEntry(formData){
		return Repository.post(`${GetEntry}`, formData);	
	},
	getEntries(payload){
		return Repository.post(`${GetEntries}?`, payload);	
	},
	login(payload)
	{
		return Repository.post(`${login}`, payload);
	}
};



