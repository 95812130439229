export const CommonMixin =  {
    data: function () {
        return{
          form: {
            UID: null,
            address : {
                 addressLine1 : "",
                 addressLine2 : "",
                 addressLine3 : "",
                 county : "",
                 country : "",
                 postcode : ""
               },
             appKey : this.$store.state.appKey,
             secToken : this.$store.state.secToken,
             firstName : "",
             lastName : "",
             dob: null,
             phoneNumber: "",
             mobileNumber: "",
             email: "",
             carReg: "",
             emergencyNumber: "",
             emergencyContact: "",
             emergencyRelationship: "",
             subEventSelected : null,
             eventSelected : null ,
             paymentType : null,
             strDietary : "",
             strMealChoice : "select",
             strComments : "",
             over18Selected: ""      
             },        
            over18Options:[
                { value: "over18", text : "Over 18"},
                { value: "under18", text : "Under 18 AND accompanied by an adult"}
              ],
              eventOptions : [
                { text : "Select a running distance", value :  null, category : "runner"},
                { text : "Select a walking distance", value :  null, category : "walker"},
                { text : "26 Mile (Challenge) runner", value :  "R26", category : "runner"},
                { text : "15 (or 13) Mile  runner", value :  "R15", category : "runner"},            
                { text : "11 Miles runner", value :  "R11", category : "runner"},
                { text : "26 Mile (Challenge) Walker", value :  "W26", category : "walker"},
                { text : "15 (or 13) Mile  Walker", value :  "W15", category : "walker"},            
                { text : "11 Miles Walker", value :  "W11", category : "walker"}
              ],
              subEventOptions : [
                { text : "A Walker", value : "walker"},
                { text : "A Runner", value : "runner"} 
              ],
              paymentOptions : [
                { text : "Bank Transfer", value: "B"},
                { text : "Cheque", value : "C"}
              ],
              mealOptions :[
                { text : "Select a meal option", value : "select"},
                { text : "Tuna pasta salad", value : "Tuna pasta salad"},
                { text : "Cheese pasta salad", value : "Cheese pasta salad"},
                { text : "Vegan Cheese pasta salad", value : "Vegan Cheese pasta salad"}
              ],
        }
    }
}