import RegistrationRepository from "./RegistrationRepository"
import SettingsRepository from "./SettingsRepository"

const repositories = {
	registration: RegistrationRepository,
	settings: SettingsRepository
}

export const RepositoryFactory = {
	get: name => repositories[name],
	put: name => repositories[name],
	post: name => repositories[name],
	delete: name => repositories[name]
	};