import Repository from './Repository';
const GetEventDetails = 'GetEventDetails.php';
const UpdateEventDetails = 'UpdateEventDetails.php';


export default {	
	getEventDetails(payload){
		return Repository.get(`${GetEventDetails}`, payload);		
	},
	updateEventDetails(payload){
		return Repository.post(`${UpdateEventDetails}`, payload);		
	},
	
};
