<template>
    <div>
        <div id="postcode-search-container" class="pb-2">
            <b-input-group>
                <b-form-input v-model="postcodeSearchInput" placeholder="Enter a Postcode" size='sm' @blur="hideResult" ></b-form-input>
                        <b-input-group-append>
                            <button type="button" class="btn btn-primary btn-lg btn-block btn-sm" @click="postcodeSearch" @blur="hideErrorMessage">
                                <b-icon icon="search"></b-icon>
                            </button> 	  
                        </b-input-group-append>
                </b-input-group>
            <div class="mt-2" v-if="displayResults">
                <b-form-select v-model='searchResultSelected' :options='searchResultsOptions' size="sm" @input="updateAddress('search')"/>
            </div>
            <b-alert :show="showError" class="text-danger customSmallText">{{errorText}}</b-alert>
        </div>
        <div id="existing-address-container">
            <div id="address-container" class="w-75">
            <b-form autocomplete="off">
                <b-form-input autocomplete="billing address-line1" v-model="addressData.addressLine1" placeholder="address line 1" type='text' @change="requiredUpdate('addressLine1')" class='mb-0 w-70' size='sm' :state="addressLine1State"></b-form-input>
                <b-form-input autocomplete="billing address-line2" v-model="addressData.addressLine2" placeholder="address line 2" type='text' class='mb-0 w-70' size='sm'></b-form-input>
                <b-form-input autocomplete="billing address-level1" v-model="addressData.addressLine3" placeholder="address line 3" type='text' class='mb-0 w-70' size='sm'></b-form-input>
                <b-form-input autocomplete="billing address-level2" v-model="addressData.county" placeholder="county" type='text' class='mb-0 w-70' size='sm'></b-form-input>
                <b-form-input autocomplete="billing country" v-model="addressData.country" placeholder="country" type='text' class='mb-0 w-70' size='sm'></b-form-input>
                <b-form-input autocomplete="billing postal-code" v-model="addressData.postcode" placeholder="Postcode" @change="requiredUpdate('postcode')" type='text' class='mb-0 w-70' size='sm' :state="postcodeState"></b-form-input>           
               </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'AddressComponent',
    components: {},
    props: ["addressData"],
    data: function(){
		return {
            postcodeSearchInput: null,
            displayResults: false
		}	
	},
	created()
	{       
 
    },
	methods:
    { 
  
        hideResult()
		{
			this.showError = false;
			this.displayResults = false;
			this.displayExisting = false;
		},
		hideErrorMessage()
		{
			this.showError = false;
        },
        requiredUpdate(value)
        {
            console.log(value)
            switch(value)
            {
                case "addressLine1":
                this.addressLine1State = (this.addressData.addressLine1.length == 0)? false : true
                break
                case "postcode":
                this.postcodeState = (this.addressData.postcode.length == 0)? false : true
                break
                case "movedInDate":
                this.movedInDateState = (this.movedInDate == null )? false : true
                break
                case "residentialStatus":
                this.residentialStatusState = (this.residentialStatus == 'select')? false : true
                break
            }
           
        },
		postcodeSearch()
		{
			let apiKey = this.$store.state.postCodeApiKey
			let postcode = this.postcodeSearchInput.replace(/ /g,'');
			let requestURL = 'https://api.getAddress.io/find/'+postcode+'?api-key=' + apiKey + '&format=True';
			axios.get(requestURL).then((response) => { // XX2 00X or TR19 7AA or KW1 4YT
				this.addressResult = response.data.addresses;
				this.addressResult.unshift("Select the address");
				this.searchResultSelected = "Select the address",
				this.displayExisting = false;
				this.displayResults = true;
				this.searchResultsOptions = this.addressResult
				this.showError = false
				}).catch(error =>{
				this.showError = true;
				switch(error.response.status)
				{
					case 400: //XX4 00X 
					this.errorText = "The postcode is not valid";
					break;
					case 401: //XX4 01X 
					this.errorText = "The api key is invalid";
					break;
					case 403: //XX4 03X 
					this.errorText = "The api key is invalid";
					break;
					case 404: //XX4 04X 
					this.errorText = "No address could be found for this postcode";
					break;
					case 429: //XX4 29X 
					this.errorText = "We are sorry the postcode search is not available at this time (429)";
					break;
					case 500: //XX5 00X 
					this.errorText = "We are sorry the postcode search is not available at this time (500)";
					break;
					default:
					this.errorText = "We are sorry the postcode search is not available at this time ";
					break;
				}			
					
			})		
		},
		updateAddress(type)
		{
            this.addressData.clientGUID = this.clientGUID
            //this.existingSelected = this.existingOptions[1]
            console.log(this.searchResultSelected[2])
            let selectedAddress = (type == "search")? this.searchResultSelected : this.existingSelected
            console.log(selectedAddress)
            console.log(type)
            console.log(this.addressData)
    
			
                this.existingSelected = 'select'
                console.log(this.existingSelected)
                
                let addressLine2 = ""
				let addressLine3 = selectedAddress[3]
				// if the selected address has a comma delimited addressline 3 split it up to insert it in lines 2 & 3 of our form (with 3 normally being the town/city element of the string) 
				// See https://getaddress.io/Documentation to see how the formatted address is returned
				if(addressLine3.indexOf(',') > -1)
				{
					let lastIndex = addressLine3.lastIndexOf(",")
					addressLine2	= addressLine3.substring(0, lastIndex).trim()
					addressLine3  = addressLine3.substring(lastIndex + 1).trim()
				}
				this.addressData.addressLine1 = selectedAddress[0]
				this.addressData.addressLine2 = (addressLine2.length == 0)? this.searchResultSelected[2] : addressLine2
				this.addressData.addressLine3 = addressLine3
				this.addressData.county = selectedAddress[4]
				this.addressData.country = "United Kingdom"
				this.addressData.postcode = this.postcodeSearchInput.toUpperCase()
                this.nonUKAddress = false
                this.movedInDate = null
                this.residentialStatus = "select"
                this.addressData.addressGUID = null
                this.addressData.addressUID = null
                this.addressData.addressType = 'client'
                
		
				this.displayResults = false;
		},
	
	}, 
	watch:{
		postcodeSearchInput: function()
		{
			let postcode = this.postcodeSearchInput;
			(postcode.length >= 5)? this.postcodeDisabled = false: this.postcodeDisabled = true;
		}
	},
    computed: {
         addressLine1State(){
             return (this.addressData.addressLine1.length == 0)? false : true
         },
        postcodeState(){
             return (this.addressData.postcode.length == 0)? false : true
         },
         newAddressLine1State(){
             return (this.newAddressData.addressLine1.length == 0)? false : true
         },
        newPostcodeState(){
             return (this.newAddressData.postcode.length == 0)? false : true
         },
        movedInDateState(){
             return (this.addressData.movedInDate == null )? false : true
         } 
    }


}
</script>
<style scoped>

 
.flex-container-row { 
display: flex;
flex-direction: row;
background-color: #fff;
justify-content: flex-start;
padding-bottom: 5px;
}


.flex-container-item {
flex-grow: 1;
flex-basis: 0;
padding-right: 50px
}
@media (min-width: 768px) { 

#postcode-search-container
{
    width: 50%
}

}
	
@media (max-width: 767.99px) { 	


}
</style>