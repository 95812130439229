import axios from "axios";

var baseDomain = "";

switch(document.location.host)
{
	case "www.scanandbook.co.uk":
		baseDomain = "https://api.scanandbook.co.uk/footfest";
		break;	
	case "www.scanandbook-preview.co.uk":
		baseDomain = "https://api.scanandbook-preview.co.uk";
		break;
	case "localhost:8081":
	case "localhost:8082":
		baseDomain = "http://localhost/footfest/public_html";
		break;
}

const baseURL = `${baseDomain}/api`;

export default axios.create({
baseURL
});